<template>
  <div class="footer">
    <v-footer app>
      <template v-if="!userIsSignIn">
        <v-spacer />
        <div class="caption footer__user_item clickable" v-on:click="toSignIn">
          {{ $t("__sign_in") }}
        </div>
      </template>
      <template v-else>
        <v-spacer />
        <div class="caption footer__user_item">{{ userName }}</div>
        <div class="caption footer__user_item clickable" v-on:click="signOut">
          {{ $t("__sign_out") }}
        </div>
      </template>
      <router-link
        class="caption footer__user_item clickable"
        :to="{ name: 'Home' }"
      >
        {{ $t("__back_to_home") }}
      </router-link>
    </v-footer>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters({
      userIsSignIn: "user/isSignIn",
      userName: "user/name",
    }),
  },
  methods: {
    toSignIn() {
      if (this.$route.name !== "SignIn") {
        if (this.$route.name === "Home") {
          this.$router.push({ name: "SignIn" });
        } else {
          let redirect =
            this.$route.name !== "SignUp"
              ? this.$route.fullPath
              : this.$route.query.redirect;
          this.$router.push({ name: "SignIn", query: { redirect } });
        }
      }
    },
    signOut() {
      this.$store.dispatch("user/signOut").then(() => {
        if (this.$route.meta.requiresAuth) {
          this.$router.replace({ name: "Home" });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.footer {
  &__user_item {
    margin-left: 8px;
    text-decoration: underline;
  }
}
.clickable {
  cursor: pointer;
}
</style>
