import Vue from "vue";
import VueRouter from "vue-router";
import { auth } from "@/plugins/firebase";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: () => import("@/views/SignIn.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/sign-up",
    name: "SignUp",
    component: () => import("@/views/SignUp.vue"),
    meta: {
      requiresAuth: false
    }
  },
  {
    path: "/verification",
    name: "Verification",
    component: () => import("@/views/Verification.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/apply-store",
    name: "ApplyStore",
    component: () => import("@/views/ApplyStore.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/edit-create-store-application-form/:id",
    name: "EditCreateStoreApplicationForm",
    component: () => import("@/views/EditCreateStoreApplicationForm.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/upload-apply-store-photo/:id",
    name: "UploadApplyStorePhoto",
    component: () => import("@/views/UploadApplyStorePhoto.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create-store-application-forms",
    name: "CreateStoreApplicationForms",
    component: () => import("@/views/CreateStoreApplicationForms.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/apply-success/:id",
    name: "ApplySuccessPage",
    component: () => import("@/views/ApplySuccessPage.vue"),
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const currentUser = auth.currentUser;
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!currentUser) {
      next({ name: "SignIn", query: { redirect: to.fullPath } });
      return;
    } else if (
      (!currentUser.emailVerified ||
        !store.getters["user/isPhoneNumberVerified"]) &&
      to.name !== "Verification"
    ) {
      next({ name: "Verification", query: { redirect: to.fullPath } });
      return;
    }
  }
  next();
});

export default router;
