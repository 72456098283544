<template>
  <div class="image-cropper">
    <div
      class="image-cropper__background"
      :style="{ backgroundImage: 'url(' + image + ')' }"
    ></div>
    <Cropper
      class="image-cropper__cropper"
      ref="cropper"
      background-class="image-cropper__cropper__background"
      :src="image"
      image-restriction="stencil"
    />
    <div class="image-cropper__actions-container">
      <v-btn class="image-cropper__actions-container__action" @click="cancel">{{
        $t("__cancel")
      }}</v-btn>
      <v-btn
        class="image-cropper__actions-container__action"
        @click="confirm"
        >{{ $t("__confirm") }}</v-btn
      >
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

export default {
  name: "ImageCropper",
  props: {
    image: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: "image/png"
    }
  },
  components: {
    Cropper
  },
  methods: {
    confirm() {
      const result = this.$refs.cropper.getResult();
      const resultDataURL = result.canvas.toDataURL(this.type);
      this.$emit("confirm", resultDataURL);
    },
    cancel() {
      this.$emit("cancel");
    }
  }
};
</script>

<style lang="scss">
.image-cropper {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: move;

  &__background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    filter: blur(8px);
  }
  &__cropper {
    max-height: 100%;
    max-width: 100%;
    &__background {
      background: none;
    }
  }
  &__actions-container {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0;
    padding: 4px;
    display: inline-flex;

    &__action {
      flex: 1 1 auto;
      margin: 4px;
    }
  }
}
</style>
