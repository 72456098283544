import { storage } from "@/plugins/firebase";
import { userAPI } from "@/plugins/service";

export const state = {
  createStoreApplicationFormID: "",
  createStoreApplicationForm: null
};
export const getters = {
  createStoreApplicationFormID(state) {
    return state.createStoreApplicationFormID;
  },
  createStoreApplicationForm(state) {
    return state.createStoreApplicationForm;
  },
  storageRef(state, getters, rootState, rootGetters) {
    const userID = rootGetters["user/uid"];
    const formID = getters.createStoreApplicationFormID;
    if (formID.length > 0) {
      return storage
        .ref()
        .child("create_store_application_forms")
        .child(`${userID}`)
        .child(`${formID}`);
    }
    return null;
  }
};
export const mutations = {
  SET_CREATE_STORE_APPLICATION_FORM_ID(state, id) {
    state.createStoreApplicationFormID = id;
  },
  SET_CREATE_STORE_APPLICATION_FORM(state, form) {
    state.createStoreApplicationForm = form;
  }
};
export const actions = {
  fetchCreateStoreApplicationForm(
    { commit, dispatch },
    { createStoreApplicationFormID }
  ) {
    if (createStoreApplicationFormID) {
      commit(
        "SET_CREATE_STORE_APPLICATION_FORM_ID",
        createStoreApplicationFormID
      );
      return dispatch("getCreateStoreApplicationForm");
    } else {
      commit("SET_CREATE_STORE_APPLICATION_FORM_ID", "");
      commit("SET_CREATE_STORE_APPLICATION_FORM", null);
    }
  },
  getCreateStoreApplicationForm({ getters, commit, dispatch }) {
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.call("getCreateStoreApplicationForm", idToken, {
          create_store_application_form_id: getters.createStoreApplicationFormID
        });
      })
      .then(result => {
        commit(
          "SET_CREATE_STORE_APPLICATION_FORM",
          result["create_store_application_form"]
        );
      });
  },
  /**
   * formData: object: {
   *   'store_name': string
   *   'owner_name': string
   *   'owner_phone_number': string
   *   'owner_email': string
   *   'store_owner_type': string
   *   'tax_id_number': string (required if store_owner_type == 'company')
   *   'store_phone_number': string
   *   'store_address_detail': object
   *   'store_bank_code': string
   *   'store_bank_account': string
   *   'store_website': string
   *   'telegram_bot_token': string
   * }
   **/
  addCreateStoreApplicationForm({ rootGetters, dispatch }, { formData }) {
    const formFieldNames = [
      "store_name",
      "store_industry_type",
      "owner_name",
      "owner_phone_number",
      "owner_email",
      "store_owner_type",
      "tax_id_number",
      "store_phone_number",
      "store_address_detail",
      "store_bank_code",
      "store_bank_account",
      "store_website",
      "telegram_bot_token"
    ];
    let data = {
      user_id: rootGetters["user/uid"]
    };
    for (const formFieldName of formFieldNames) {
      data[formFieldName] = formData[formFieldName];
    }
    return dispatch("user/getUserIDToken", {}, { root: true })
      .then(idToken => {
        return userAPI.call("addCreateStoreApplicationForm", idToken, data);
      })
      .then(result => {
        return result.create_store_application_form_id;
      });
  },
  /**
   * setData: object: {
   *   'store_name': string
   *    'store_industry_type': string
   *   'owner_name': string
   *   'owner_phone_number': string
   *   'owner_email': string
   *   'store_owner_type': string
   *   'tax_id_number': string
   *   'store_phone_number': string
   *   'store_address_detail': object
   *   'store_bank_code': string
   *   'store_bank_account': string
   *   'store_website': string
   *   'telegram_bot_token': string
   * }
   **/
  setCreateStoreApplicationForm({ getters, dispatch }, { setData }) {
    let data = {
      create_store_application_form_id: getters.createStoreApplicationFormID,
      set_data: setData
    };
    return dispatch("user/getUserIDToken", {}, { root: true }).then(idToken => {
      return userAPI.call("setCreateStoreApplicationForm", idToken, data);
    });
  },
  /**
   * photoName: string
   *   bank_passbook
   *   location_with_owner
   *   environment_with_owner
   *   registration_certificate
   *   owner_id_card
   *   owner_id_card_with_owner
   * photoIndex: number
   * photoURL: string
   **/
  setCreateStoreApplicationFormPhotoURL(
    { getters, dispatch },
    { photoName, photoIndex, photoURL }
  ) {
    let data = {
      create_store_application_form_id: getters.createStoreApplicationFormID,
      photo_name: photoName,
      photo_index: photoIndex,
      photo_url: photoURL
    };
    return dispatch("user/getUserIDToken", {}, { root: true }).then(idToken => {
      return userAPI.call(
        "setCreateStoreApplicationFormPhotoURL",
        idToken,
        data
      );
    });
  },
  /**
   * photoName: string
   *   bank_passbook
   *   location_with_owner
   *   environment_with_owner
   *   registration_certificate
   *   owner_id_card
   *   owner_id_card_with_owner
   * photoExtension: string
   * photoDataURL: string (data url)
   **/
  uploadPhoto(
    { getters, dispatch },
    { photoName, photoIndex, photoExtension, photoDataURL }
  ) {
    return new Promise((resolve, reject) => {
      if (
        photoName !== "bank_passbook" &&
        photoName !== "store_sign" &&
        photoName !== "environment" &&
        photoName !== "registration_certificate" &&
        photoName !== "owner_id_card"
      )
        reject("photo name is invalid");
      try {
        const storageRef = getters.storageRef;
        storageRef
          ?.child(`${photoName}_${photoIndex}.${photoExtension}`)
          .putString(photoDataURL, "data_url")
          .then(snapshot => {
            return snapshot.ref.getDownloadURL();
          })
          .then(downloadURL => {
            console.log(downloadURL);
            return dispatch("setCreateStoreApplicationFormPhotoURL", {
              photoName,
              photoIndex,
              photoURL: downloadURL
            });
          })
          .then(() => {
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } catch (err) {
        reject(err);
      }
    });
  },
  submitCreateStoreApplicationForm({ getters, dispatch }) {
    return dispatch("user/getUserIDToken", {}, { root: true }).then(idToken => {
      return userAPI.call("submitCreateStoreApplicationForm", idToken, {
        create_store_application_form_id: getters.createStoreApplicationFormID
      });
    });
  }
};
