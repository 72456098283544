<template>
  <div class="app-bar">
    <v-app-bar app color="primary" dark>
      <v-spacer v-if="isMobile" />
      <a
        href="https://www.chia-market.com/"
        target="_blank"
        class="d-flex align-center logo-router-link"
      >
        <v-img
          alt="Logo"
          class="shrink mr-4"
          contain
          src="../assets/logo.svg"
          transition="scale-transition"
          width="36"
        />
        <v-toolbar-title class="headline">
          {{ $t("__title") }}
        </v-toolbar-title>
      </a>
      <v-spacer />
      <LanguageSelector class="language-selector" :isMobile="isMobile" />
    </v-app-bar>
  </div>
</template>

<script>
import LanguageSelector from "./LanguageSelector";
import { mapGetters } from "vuex";

export default {
  name: "AppBar",
  components: {
    LanguageSelector,
  },
  computed: {
    ...mapGetters({
      isMobile: "isMobile",
    }),
  },
};
</script>

<style scoped>
.logo-router-link {
  color: inherit;
  text-decoration: inherit;
}
.language-selector {
  position: absolute;
  right: 0;
}
</style>