import { firestore, auth, Timestamp } from '@/plugins/firebase'

export const state = {
  firebaseUser: null,
  data: null,
  isEMailVerified: false,
  isPhoneNumberVerified: false
}
export const getters = {
  isSignIn(state) {
    return !!state.firebaseUser
  },
  firebaseUser(state) {
    return state.firebaseUser
  },
  data(state) {
    return state.data
  },
  uid(state) {
    state.firebaseUser
    return state.firebaseUser? state.firebaseUser.uid : ''
  },
  firstName(state) {
    state.data
    return (state.data && state.data.first_name)? state.data.first_name : ''
  },
  lastName(state) {
    state.data
    return (state.data && state.data.last_name)? state.data.last_name : ''
  },
  name(state, getters) {
    getters.firstName
    getters.lastName
    return getters.firstName + ' ' + getters.lastName
  },
  email(state) {
    state.data
    return (state.data && state.data.email)? state.data.email : ''
  },
  isEMailVerified(state) {
    return state.isEMailVerified
  },
  phoneNumber(state) {
    state.data
    return (state.data && state.data.phone_number)? state.data.phone_number : ''
  },
  isPhoneNumberVerified(state) {
    return state.isPhoneNumberVerified
  },
  firestoreColRef() {
    return firestore.collection('users')
  },
  firestoreDocRef(state, getters) {
    return state.firebaseUser? getters.firestoreColRef.doc(getters.uid) : null
  }
}
export const mutations = {
  SET_FIREBASE_USER(state, user) {
    state.firebaseUser = user
    if (state.firebaseUser && state.data) {
      state.isEMailVerified = state.firebaseUser.emailVerified
      state.isPhoneNumberVerified = state.firebaseUser.phoneNumber === state.data.phone_number
    } else {
      state.isEMailVerified = false
      state.isPhoneNumberVerified = false
    }
  },
  SET_USER_DATA(state, data) {
    state.data = data
    if (state.firebaseUser && state.data) {
      state.isEMailVerified = state.firebaseUser.emailVerified
      state.isPhoneNumberVerified = state.firebaseUser.phoneNumber === state.data.phone_number
    } else {
      state.isEMailVerified = false
      state.isPhoneNumberVerified = false
    }
  }
}
export const actions = {
  fetchUser({ commit, dispatch }, user) {
    if (user) {
      commit('SET_FIREBASE_USER', user)
      return dispatch('getUserData')
    } else {
      commit('SET_FIREBASE_USER', null)
      commit('SET_USER_DATA', null)
    }
  },
  onAuthStateChanged ({ dispatch }, then) {
    auth.onAuthStateChanged(async user => {
      await dispatch('fetchUser', user)
      then(user)
    })
  },
  signUpWithEmailAndPassword ({ getters }, { email, password }) {
    return auth.createUserWithEmailAndPassword(email, password)
    .then(userCredential => {
      const user = userCredential.user
      return getters.firestoreColRef.doc(user.uid).set({
        'email': email,
        'join_time': Timestamp.now(),
        'update_time': Timestamp.now()
      })
    })
  },
  signInWithEmailAndPassword (_, { email, password }) {
    return auth.signInWithEmailAndPassword(email, password)
  },
  signOut () {
    return auth.signOut()
  },
  reloadFirebaseUser({ state, commit }) {
    return new Promise((resolve, reject) => {
      state.firebaseUser?.reload()
      .then(() => {
        const firebaseUser = auth.currentUser
        commit('SET_FIREBASE_USER', firebaseUser)
        resolve(firebaseUser)
      })
      .catch(err => reject(err))
    })
  },
  getUserIDToken({ state }) {
    return state.firebaseUser?.getIdToken()
  },
  getUserData({ commit, getters }) {
    return new Promise((resolve, reject) => {
      getters.firestoreDocRef?.get()
      .then(docSnap => {
        const data = docSnap.data()
        commit('SET_USER_DATA', data)
        resolve(data)
      })
      .catch(err => reject(err))
    })
  },
  updateUserData({ getters }, updateData) {
    return new Promise((resolve, reject) => {
      updateData.update_time = Timestamp.now()
      getters.firestoreDocRef?.update(updateData)
      .then(() => resolve())
      .catch(err => reject(err))
    })
  },
  updateUserName({ state, dispatch }, { firstName, lastName }) {
    return new Promise((resolve, reject) => {
      dispatch('updateUserData', { 
        'first_name': firstName,
        'last_name': lastName
      })
      .then(() => {
        return state.firebaseUser?.updateProfile({
          displayName: firstName + ' ' + lastName
        })
      })
      .then(() =>  resolve())
      .catch(err => reject(err))
    })
  },
  updatePhoneNumber({ dispatch }, phoneNumber) {
    return new Promise((resolve, reject) => {
      dispatch('updateUserData', { 
        'phone_number': phoneNumber
      })
      .then(() =>  resolve())
      .catch(err => reject(err))
    })
  },
  updateJoinInvitationCode({ dispatch }, invitationCode) {
    return new Promise((resolve, reject) => {
      dispatch('updateUserData', { 
        'join_invitation_code': invitationCode
      })
      .then(() =>  resolve())
      .catch(err => reject(err))
    })
  },
}