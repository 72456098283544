import { userAPI } from "@/plugins/service"

export const state = {
  createStoreApplicationFormIDs: [],
  createStoreApplicationForms: {}
}
export const getters = {
  createStoreApplicationFormIDs(state) {
    return state.createStoreApplicationFormIDs
  },
  createStoreApplicationForms(state) {
    return state.createStoreApplicationForms
  }
}
export const mutations = {
  SET_CREATE_STORE_APPLICATION_FORMS(state, { ids, forms }) {
    state.createStoreApplicationFormIDs = ids
    state.createStoreApplicationForms = forms
  }
}
export const actions = {
  getCreateStoreApplicationForms({ commit, rootGetters, dispatch }, { status }) {
    return dispatch('user/getUserIDToken', {}, {root:true})
    .then(idToken => {
      return userAPI.call('getCreateStoreApplicationForms', idToken, {
        'user_id': rootGetters['user/uid'],
        'status': status
      })
    })
    .then(result => {
      commit('SET_CREATE_STORE_APPLICATION_FORMS', {
        ids: result['create_store_application_form_ids'],
        forms: result['create_store_application_forms']
      })
    })
  },
  submitCreateStoreApplicationForm({ dispatch }, { formID }) {
    return dispatch('user/getUserIDToken', {}, {root:true})
    .then(idToken => {
      return userAPI.call('submitCreateStoreApplicationForm', idToken, {
        'create_store_application_form_id': formID,
      })
    })
  }
}